<template>
  <p v-if="geenShiftEnDatumVerleden" class="block font-bold text-red-500">
    {{ text }}
  </p>
</template>

<script setup>
import { computed, defineProps } from 'vue'

import useNotification from '@/hooks/useNotification'

const props = defineProps({
  ritDatetime: [Number, String, Object, Date],
  shiftStart: [Number, String, Object, Date],
})

const geenShiftEnDatumVerleden = computed(() => {
  if (!props.ritDatetime || !!props.shiftStart) return false
  const now = Date.now()
  const time = new Date(props.ritDatetime).getTime()
  return now > time
})

const text = 'OPGELET! Shift vergeten!'

if (geenShiftEnDatumVerleden.value) {
  useNotification(text, 'error')
}
</script>
